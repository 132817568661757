/* You can add global styles to this file, and also import other style files */
@import 'app/layout/style';
@import 'styles/themes';
@import 'styles/custom';
@import 'styles/grid';
@import 'styles/helpers';
@import 'styles/plugins';

.oha-blue-bg {
  background-color: #005595;
}

.oha-orange-bg {
  background-color: #EC891D;
}

.large-icon {
  transform: scale(3);
}

.medium-icon {
  transform: scale(2);
}

.icon-button {
  cursor: pointer;
}

.file-input {
  display: none;
}

