@use '@angular/material' as mat;
@import '../style/variables';

@mixin matero-sidebar-theme($theme) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .matero-sidebar-header {
    color: mat.get-color-from-palette($foreground, text);
    background: mat.get-color-from-palette($background, dialog);
    border-bottom: 1px solid mat.get-color-from-palette($foreground, divider);
  }

  .matero-user-panel {
    border-bottom: 1px solid mat.get-color-from-palette($foreground, divider);
  }
}

@mixin matero-sidemenu-theme($theme) {
  $is-dark-theme: map-get($theme, is-dark);
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .matero-sidemenu {
    > .matero-sidemenu-item {
      > a {
        color: mat.get-color-from-palette($foreground, text);

        &:hover {
          background: mat.get-color-from-palette($background, hover);
        }
      }

      &.active {
        > a {
          background-color: if($is-dark-theme, rgba(mat.get-color-from-palette($primary), .15), transparent);
          color: if($is-dark-theme, lighten(mat.get-color-from-palette($primary), 25%), mat.get-color-from-palette($accent));

          &:hover {
            background: mat.get-color-from-palette($background, hover);
          }
        }
      }

      &.open {
        background: mat.get-color-from-palette($background, hover);
      }
    }

    &.matero-sidemenu-sub {
      > .matero-sidemenu-item {
        &.active {
          > a {
            background-color: transparent;

            &:hover {
              background: mat.get-color-from-palette($background, hover);
            }
          }
        }

        &.open {
          background: transparent;
        }
      }
    }
  }
}
